<template>
  <div class="h-fit flex flex-col bg-gray-100 dark:bg-gray-900">

    <BaseHeader />
    <div class="mx-auto max-w-7xl w-full">
      <div class="flex justify-between relative">
        <BaseBreadcrumbs v-if="shouldShowBreadcrumbs" />
      </div>
    </div>

    <slot />
    <BaseFooter />
  </div>
</template>

<script>

export default {
  computed: {
    isNotRootPath() {
      return this.$route.path !== '/';
    },
    shouldShowBreadcrumbs() {
      return this.isNotRootPath;
    },
  },
};
</script>

<style>
html {
  @apply
  overflow-x-hidden
}

header, footer {
  @apply
  p-0
  bg-[#F5F5F5]
}
</style>