<template>
  <nav class="breadcrumbs-container" v-if="auth">
    <div class="breadcrumb-item-first">
      <NuxtLink to="/">Главная</NuxtLink>
    </div>
    <div v-for="(crumb, index) in breadcrumbs" :key="index" class="breadcrumb-item">
      <NuxtLink :to="crumb.route ? crumb.route : previousRoutePath(crumb)">
        {{ translateLabel(crumb.label) }}
      </NuxtLink>
    </div>
  </nav>
</template>

<script>
import { useUserData } from '~/stores/userData';
import { translations } from '~/plugins/nuxt-i18n';


export default {
  data() {
    return {
      auth: '',
      crumb: '',
    }
  },
  mounted() {
    this.auth = useUserData().authenticated
  },
  computed: {
    breadcrumbs() {
      const routePath = this.$route.path

      return routePath
          .split('/')
          .filter((route) => route)
          .map((route, index, routes) => {
            let label = route.charAt(0) + route.slice(1)

            if (index === routes.length - 1) {
              label = index > 0 ? `${label}` : label
            }

            const routePath = `/${routes.slice(0, index + 1).join('/')}`

            return {
              label,
              route: routePath === this.crumb?.route ? null : routePath,
            }
          })
    },
  },
  methods: {
    previousRoutePath(crumb) {
      const previousRoute = this.$route.matched[this.$route.matched.length - 2];
      return previousRoute.path + (crumb.route ? `/${crumb.route}` : '');
    },
    translateLabel(label) {
      return translations[label] || label;
    },
  },
  created() {
    this.auth = useUserData().authenticated;
  },  
}
</script>

<style scoped>
.breadcrumbs-container {
  @apply
  hidden
  sm:flex
  text-xs
  mx-auto
  max-w-7xl
  w-full
  px-5
  lg:px-10
  pt-1
  pb-8
  text-gray02-80
  dark:text-gray02-60
}

.breadcrumb-item-first {
  @apply
  font-thin
  hover:text-black
  dark:hover:text-primary-dark
}


.breadcrumb-item {
  @apply
  font-thin
  before:content-['/']
  before:inline-block
  before:mx-1
  hover:text-black
  dark:hover:text-primary-dark
}

</style>